@import url("https://fonts.cdnfonts.com/css/euclid-circular-a");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Cairo:wght@200..1000&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.whatsapp-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #25d366;
  border-radius: 50px;
  padding: 10px 15px;
  color: white;
  text-decoration: none;
  display: flex;
  align-items: center;
  font-size: 16px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3);
  z-index: 10;
}

.whatsapp-button img {
  width: 24px;
  height: 24px;
}

.whatsapp-button:hover {
  background-color: #22b05a;
}

.topbar small{
  color: #fff;
  font-size: 14px;
}

:root {
  --primary: #FF4131;
  --secondary: #9B9B9B;
  --light: #F5F5F5;
  --dark: #161616;
}
.text-primary {
  color: #FF4131 !important
}
.btn-primary {
  background-color: #FF4131 !important;
  border: 1px solid #FF4131 !important;
}
.bg-primary {
  background-color: #FF4131 !important;
  border: 1px solid #FF4131 !important;
}
.navbar h2{
  font-family: "Euclid Circular A", sans-serif;
  font-size: 24px;
  color: #65B530;
  font-weight: 700;
}
.fw-medium {
  font-weight: 500 !important;
}

.fw-bold {
  font-weight: 700 !important;
}

.fw-black {
  font-weight: 900 !important;
}

.back-to-top {
  position: fixed;
  display: none;
  right: 45px;
  bottom: 45px;
  z-index: 99;
}


/*** Spinner ***/
#spinner {
  opacity: 0;
  visibility: hidden;
  transition: opacity .5s ease-out, visibility 0s linear .5s;
  z-index: 99999;
}

#spinner.show {
  transition: opacity .5s ease-out, visibility 0s linear 0s;
  visibility: visible;
  opacity: 1;
}


/*** Button ***/
.btn {
  font-weight: 500;
  transition: .5s;
}

.btn.btn-primary {
  color: #FFFFFF;
}

.btn-square {
  width: 38px;
  height: 38px;
}

.btn-sm-square {
  width: 32px;
  height: 32px;
}

.btn-lg-square {
  width: 48px;
  height: 48px;
}

.btn-square,
.btn-sm-square,
.btn-lg-square {
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: normal;
}

/*** Navbar ***/
.navbar.sticky-top {
  top: -100px;
  transition: .5s;
}

.navbar .navbar-brand {
  height: 75px;
}

.navbar .navbar-nav .nav-link {
  margin-right: 30px;
  padding: 25px 0;
  color: var(--dark);
  font-weight: 500;
  outline: none;
}

.navbar .navbar-nav .nav-link:hover,
.navbar .navbar-nav .nav-link.active {
  color: var(--primary);
}

.navbar .dropdown-toggle::after {
  border: none;
  content: "\f107";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  vertical-align: middle;
  margin-left: 8px;
}

@media (max-width: 991.98px) {
  .navbar .navbar-nav .nav-link  {
      margin-right: 0;
      padding: 10px 0;
  }

  .navbar .navbar-nav {
      border-top: 1px solid #EEEEEE;
  }
}

@media (min-width: 992px) {
  .navbar .nav-item .dropdown-menu {
      display: block;
      border: none;
      margin-top: 0;
      top: 150%;
      opacity: 0;
      visibility: hidden;
      transition: .5s;
  }

  .navbar .nav-item:hover .dropdown-menu {
      top: 100%;
      visibility: visible;
      transition: .5s;
      opacity: 1;
  }
}

.navbar .btn:hover {
  color: #FFFFFF !important;
  background: var(--primary) !important;
}


/*** Header ***/
.header-carousel .carousel-inner {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  background: rgba(22, 22, 22, .7);
}

@media (max-width: 768px) {
  .header-carousel .owl-carousel-item {
      position: relative;
      min-height: 500px;
  }
  
  .header-carousel .owl-carousel-item img {
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: cover;
  }

  .header-carousel .owl-carousel-item h5,
  .header-carousel .owl-carousel-item p {
      font-size: 14px !important;
      font-weight: 400 !important;
  }

  .header-carousel .owl-carousel-item h1 {
      font-size: 30px;
      font-weight: 600;
  }
}

.header-carousel .owl-nav {
  position: absolute;
  width: 200px;
  height: 45px;
  bottom: 30px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: space-between;
}

.header-carousel .owl-nav .owl-prev,
.header-carousel .owl-nav .owl-next {
  width: 45px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #FFFFFF;
  background: transparent;
  border: 1px solid #FFFFFF;
  border-radius: 45px;
  font-size: 22px;
  transition: .5s;
}

.header-carousel .owl-nav .owl-prev:hover,
.header-carousel .owl-nav .owl-next:hover {
  background: var(--primary);
  border-color: var(--primary);
}

.header-carousel .owl-dots {
  position: absolute;
  height: 45px;
  bottom: 30px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
}

.header-carousel .owl-dot {
  position: relative;
  display: inline-block;
  margin: 0 5px;
  width: 15px;
  height: 15px;
  background: transparent;
  border: 1px solid #FFFFFF;
  border-radius: 15px;
  transition: .5s;
}

.header-carousel .owl-dot::after {
  position: absolute;
  content: "";
  width: 5px;
  height: 5px;
  top: 4px;
  left: 4px;
  background: #FFFFFF;
  border-radius: 5px;
}

.header-carousel .owl-dot.active {
  background: var(--primary);
  border-color: var(--primary);
}

.page-header {
  background: linear-gradient(rgba(22, 22, 22, .7), rgba(22, 22, 22, .7)), url(images/carousel-1.jpg) center center no-repeat;
  background-size: cover;
}

.breadcrumb-item + .breadcrumb-item::before {
  color: var(--light);
}

.about-text p{
  font-family: "Euclid Circualar A", sans-serif;
  line-height: 25px;
}
/*** About ***/
@media (min-width: 992px) {
  .container.about {
      max-width: 100% !important;
  }

  .about-text  {
      padding-right: calc(((100% - 960px) / 2) + .75rem);
      font-family: "Euclid Circualar A", sans-serif;
  }
}

@media (min-width: 1200px) {
  .about-text  {
      padding-right: calc(((100% - 1140px) / 2) + .75rem);
      font-family: "Euclid Circualar A", sans-serif;
  }
}

@media (min-width: 1400px) {
  .about-text  {
      padding-right: calc(((100% - 1320px) / 2) + .75rem);
      font-family: "Euclid Circualar A", sans-serif;
  }
}


/*** Service ***/
.service-row {
  box-shadow: 0 0 45px rgba(0, 0, 0, .08);
}

.service-item {
  border-color: rgba(0, 0, 0, .03) !important;
}

.service-item .btn {
  width: 38px;
  height: 38px;
  display: inline-flex;
  align-items: center;
  color: #FFFFFF;
  background: var(--primary);
  border-radius: 38px;
  white-space: nowrap;
  overflow: hidden;
  transition: .5s;
}

.service-item:hover .btn {
  width: 140px;
}


/*** Feature ***/
@media (min-width: 992px) {
  .container.feature {
      max-width: 100% !important;
  }

  .feature-text  {
      padding-left: calc(((100% - 960px) / 2) + .75rem);
  }
}

@media (min-width: 1200px) {
  .feature-text  {
      padding-left: calc(((100% - 1140px) / 2) + .75rem);
  }
}

@media (min-width: 1400px) {
  .feature-text  {
      padding-left: calc(((100% - 1320px) / 2) + .75rem);
  }
}


/*** Project Portfolio ***/
#portfolio-flters li {
  display: inline-block;
  font-weight: 500;
  color: var(--dark);
  cursor: pointer;
  transition: .5s;
  border-bottom: 2px solid transparent;
}

#portfolio-flters li:hover,
#portfolio-flters li.active {
  color: var(--primary);
  border-color: var(--primary);
}

.portfolio-inner {
  position: relative;
  overflow: hidden;
  box-shadow: 0 0 45px rgba(0, 0, 0, .07);
}

.portfolio-inner img {
  transition: .5s;
  height: 300px;
  object-fit: cover;
}

.portfolio-inner:hover img {
  transform: scale(1.1);
}

.portfolio-inner .portfolio-text {
  position: absolute;
  width: 100%;
  left: 0;
  bottom: -50px;
  opacity: 0;
  transition: .5s;
}

.portfolio-inner:hover .portfolio-text {
  bottom: 0;
  opacity: 1;
}


/*** Quote ***/
@media (min-width: 992px) {
  .container.quote {
      max-width: 100% !important;
  }

  .quote-text  {
      padding-right: calc(((100% - 960px) / 2) + .75rem);
  }
}

@media (min-width: 1200px) {
  .quote-text  {
      padding-right: calc(((100% - 1140px) / 2) + .75rem);
  }
}

@media (min-width: 1400px) {
  .quote-text  {
      padding-right: calc(((100% - 1320px) / 2) + .75rem);
  }
}


/*** Team ***/
.team-item {
  box-shadow: 0 0 45px rgba(0, 0, 0, .08);
}

.team-item img {
  transition: .5s;
}

.team-item:hover img {
  transform: scale(1.1);
}

.team-item .team-social {
  position: absolute;
  width: 0;
  height: 0;
  top: 50%;
  left: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(22, 22, 22, .7);
  opacity: 0;
  transition: .5s;
}

.team-item:hover .team-social {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 1;
}

.team-item .team-social .btn {
  opacity: 0;
}

.team-item:hover .team-social .btn {
  opacity: 1;
}

.team-social small{
  color: #fff;
  text-align: center;
  font-family: "Euclid Circular A", sans-serif;
  font-size: 13px;
  line-height: 20px;
}

/*** Testimonial ***/
.testimonial-carousel {
  display: flex !important;
  flex-direction: column-reverse;
  max-width: 700px;
  margin: 0 auto;
}

.testimonial-carousel .owl-dots {
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
}

.testimonial-carousel .owl-dots .owl-dot {
  position: relative;
  width: 60px;
  height: 60px;
  margin: 0 5px;
  transition: .5s;
}

.testimonial-carousel .owl-dots .owl-dot.active {
  width: 100px;
  height: 100px;
}

.testimonial-carousel .owl-dots .owl-dot::after {
  position: absolute;
  width: 40px;
  height: 40px;
  bottom: -20px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  content: "\f10d";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  color: var(--primary);
  background: #FFFFFF;
  border-radius: 40px;
  transition: .5s;
  opacity: 0;
}

.testimonial-carousel .owl-dots .owl-dot.active::after {
  opacity: 1;
}

.testimonial-carousel .owl-dots .owl-dot img {
  border-radius: 60px;
  opacity: .4;
  transition: .5s;
}

.testimonial-carousel .owl-dots .owl-dot.active img {
  opacity: 1;
}


/*** Contact ***/
@media (min-width: 992px) {
  .container.contact {
      max-width: 100% !important;
  }

  .contact-text  {
      padding-left: calc(((100% - 960px) / 2) + .75rem);
  }
}

@media (min-width: 1200px) {
  .contact-text  {
      padding-left: calc(((100% - 1140px) / 2) + .75rem);
  }
}

@media (min-width: 1400px) {
  .contact-text  {
      padding-left: calc(((100% - 1320px) / 2) + .75rem);
  }
}


/*** Footer ***/
.footer .btn.btn-link {
  display: block;
  margin-bottom: 5px;
  padding: 0;
  text-align: left;
  color: var(--secondary);
  font-weight: normal;
  text-transform: capitalize;
  transition: .3s;
}

.footer .btn.btn-link::before {
  position: relative;
  content: "\f105";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  margin-right: 10px;
}

.footer .btn.btn-link:hover {
  color: var(--primary);
  letter-spacing: 1px;
  box-shadow: none;
}



.carousel-item-wrapper {
  position: relative;
}

.carousel-item-wrapper img {
  width: 100%;
  height:600px;
  object-fit: cover;
}

.carousel-item-wrapper::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Black overlay with 50% opacity */
  z-index: 5; /* Below the content but above the image */
}

.carousel-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
  text-align: center;
  color: white;
}

.carousel-content h1,
.carousel-content p {
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}
.carousel-content h1{
  font-family: "Euclid Circular A", sans-serif;
  font-size: 62px;
  font-weight: 700;
}
.carousel-content p{
  font-family: "Euclid Circular A", sans-serif;
  font-size: 22px;
  font-weight: 500;
}
.facts-box{
  font-family: "Euclid Circular A", sans-serif;
  font-weight: 500;
}
.facts-box h1{
color: #000;
font-weight: 600;
}
.icon-container img{
  max-width: 150px;
}
.about-height {
  min-height: 400px;
}
.about-img img{
  object-fit: cover;
}
.diff-height{
  width: 60px;
  height: 2px;
}
.same-height{
  width: 64px;
  height: 64px;
}
.service-row{
  font-family: "Euclid Circular A", sans-serif;
}
.service-row h4{
  font-size: 25px;
}
.div-heading{
  font-weight: 700;
}
.div-img{
  object-fit: cover;
}
.form-box input{
  height: 50px;
}
.form-box select{
  height: 50px;
}
.map-class{
  object-fit: cover;
}
.contact-float textarea{
  height: 100px;
}

.float{
	position:fixed;
  display: flex;
  align-items: center;
	/* width:60px;
	height:60px; */
	bottom:40px;
	right:40px;
	background-color:#25d366;
	color:#FFF;
	border-radius:50px;
	text-align:center;
  font-size:30px;
	box-shadow: 2px 2px 3px #999;
  z-index:100;
  text-decoration: none;
  padding: 10px;
}
.my-float{
  font-size: 22px;
}

.float small{
  font-size: 14px;
  margin-bottom: 0;
  padding-bottom: 0;
  font-family: "Euclid Circular A", sans-serif;
}
.float:hover{
  color: #fff;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  transition: all 0.3s;
  transform: scale(1.08);
}
.about-img-box img{
  object-fit: cover;
}
.nav-call{
  text-decoration: none;
}
.carousel-next-arrow,
.carousel-prev-arrow {
  position: absolute;
  top: 50%;
  z-index: 1;
  background: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
  transform: translateY(-50%);
  font-size: 20px;
  transition: background 0.3s;
}

.carousel-next-arrow {
  right: 10px;
}

.carousel-prev-arrow {
  left: 10px;
}

.carousel-next-arrow:hover,
.carousel-prev-arrow:hover {
  background: rgba(0, 0, 0, 0.8);
}


.service-img-box img{
  width: 100%;
  border-radius: 10px;
}
.service-text-box h4{
  font-size: 30px;
  font-weight: 500;
  margin-bottom: 0;
  padding-bottom: 0;
  font-family: "Euclid Circular A", sans-serif;
}
.service-text-box p{
  font-size: 17px;
  color: #414141;
  font-weight: 500;
  margin-bottom: 0;
  padding-bottom: 0;
  font-family: "Euclid Circular A", sans-serif;
  line-height: 30px;
}
.service-text-box button{
  padding: 10px 40px;
  border-radius: 20px;
  background-color: #FF4131;
  border: none;
  color: #fff;
  font-family: "Euclid Circular A", sans-serif;
}
.inner-service .row{
padding-top: 20px;
}


.section-services {
	font-family: "Poppins", sans-serif;
	color: #202020;
}

.section-services .header-section {
	margin-bottom: 34px;
}

.section-services .header-section .title {
	position: relative;
    padding-bottom: 14px;
    margin-bottom: 25px;
	font-weight: 700;
    font-size: 32px;
}

.section-services .header-section .title:before {
	content: "";
	position: absolute;
	bottom: 0;
	left: 50%;
	transform: translateX(-50%);
	width: 50px;
	height: 3px;
	background-color: #ff4500;
    border-radius: 3px;
}

.section-services .header-section .title:after {
	content: "";
	position: absolute;
	bottom: 0;
	left: 50%;
    transform: translateX(30px);
	width: 10px;
	height: 3px;
	background-color: #504f93;
    border-radius: 3px;
}

.section-services .header-section .description {
	font-size: 14px;
    color: #282828;
}

.section-services .single-service {
    position: relative;
    margin-top: 30px;
    background-color: #f2f2f2;
    border-radius: 10px;
    padding: 40px 30px;
    overflow: hidden;
}

.section-services .single-service .content {
	position: relative;
	z-index: 20;
}

.section-services .single-service .circle-before {
    position: absolute;
    top: 0;
    right: 0px;
    transform: translate(40%, -40%);
    width: 150px;
    height: 150px;
    background-color: #FF4131;
    border-radius: 50%;
    opacity: 0.5;
    z-index: 10;
    transition: all .6s;
}

.section-services .single-service:hover .circle-before {
	width: 100%;
	height: 100%;
	transform: none;
	border: 0;
	border-radius: 0;
	opacity: 1;
}

.section-services .single-service .icon {
	display: inline-block;
	margin-bottom: 26px;
    width: 70px;
    height: 70px;
    background-color: #FF4131;
    border-radius: 5px;
    line-height: 70px;
    text-align: center;
    color: #fff;
    font-size: 30px;
    transition: all .3s;
}

.section-services .single-service:hover .icon {
	background-color: #fff;
	color: #FF4131;
}

.section-services .single-service .title {
    margin-bottom: 18px;
	font-weight: 700;
    font-size: 23px;
    transition: color .3s;
}

.section-services .single-service:hover .title {
	color: #fff;
}

.section-services .single-service .description {
    margin-bottom: 20px;
    font-size: 14px;
    transition: color .3s;
}

.section-services .single-service:hover .description {
	color: #fff;
}

.section-services .single-service a {
	position: relative;
	font-size: 18px;
    color: #202020;
    text-decoration: none;
    font-weight: 500;
    transition: color .3s;
}

.section-services .single-service:hover a {
	color: #fff;
}

.section-services .single-service a:after {
	content: "";
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	height: 1px;
	background-color: #FF4131;
	transition: background-color .3s;
}

.section-services .single-service:hover a:after {
	background-color: #fff;
}


.wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  font-family: "Euclid Circular A", sans-serif;
}

.card {
  overflow: hidden;
  box-shadow: 0px 2px 20px #d7dfe2;
  background: white;
  border-radius: 0.5rem;
  position: relative;
  width: 300px;
  margin: 1rem;
  transition: 250ms all ease-in-out;
  cursor: pointer;
  text-decoration: none;
}

.card:hover {
  transform: scale(1.05);
  box-shadow: 0px 2px 40px #d7dfe2;
}

.banner-img {
  position: absolute;
  object-fit: cover;
  height: 14rem;
  width: 100%;
}

.category-tag {
  font-size: 0.8rem;
  font-weight: bold;
  color: white;
  background: red;
  padding: 0.5rem 1.3rem 0.5rem 1rem;
  text-transform: uppercase;
  position: absolute;
  z-index: 1;
  top: 1rem;
  border-radius: 0 2rem 2rem 0;
}

.popular {
  background: #FF4131;
}

.technology {
  background: #651fff;
}

.psychology {
  background: #e85808;
}

.card-body {
  margin: 14rem 0 0.2rem 0;
}
.card-body h2{
  font-size: 20px;
  line-height: 25px;
}
.card-profile-info p{
  padding-bottom: 0;
  margin-bottom: 0;
}
.blog-hashtag {
  font-size: 0.9rem;
  font-weight: 500;
  color: #4d97b2;
}

.blog-title {
  line-height: 1.5rem;
  margin: 1rem 0 0.5rem;
  color: #0f0f0f;
}

.blog-description {
  color: #616b74;
  font-size: 0.9rem;
}

.card-profile {
  display: flex;
  margin-top: 2rem;
  align-items: center;
}

.profile-img {
  width: 60px;
  height: 60px;
  object-fit: cover;
  border-radius: 50%;
}

.card-profile-info {
  margin-left: 1rem;
}

.profile-name {
  font-size: 1rem;
  color: #0f0f0f;
}

.profile-followers {
  color: #616b74;
  font-size: 0.9rem;
}

.blog-detail-page img{
  width: 100%;
  max-height: 500px;
  object-fit: cover;
}
.blog-content h4{
  font-family: "Euclid Circular A", sans-serif;
  font-weight: 500;
  font-size: 32px;
  color: #121212;
  margin-top: 15px;
}
.blog-content p{
  font-family: "Euclid Circular A", sans-serif;
  font-weight: 400;
  font-size: 18px;
  color: #121212;
  margin-top: 15px;
  line-height: 35px;
}
.blog-box{
  background-color: #fff0ee;
  max-width: 800px;
  padding: 10px 15px;
  border-left: 5px solid #FF4131;
}

.service-gif-content{
  display: flex;
  margin-top: 25px;
  background-color: #fff4f3;
  border-left: 4px solid #FF4131;
  padding: 10px 15px;
}
.service-gif-content:hover{
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  transition: all ease-in 0.1s;
  border-left: 2px;
  border-radius: 5px;
  border: 2px solid #000000;
  color: #fff;
  background-image: linear-gradient(to bottom, rgb(0, 0, 0, 0.674), rgba(0, 0, 0, 0.674)), url(images/data.jpeg);

}
.service-gif-content:hover h6{
color: #fff;
}
.service-gif-content:hover p{
  color: #fff;
  }
.service-gif-content img{
  width: 90px;
  height: 76.5;
}
.service-gif-content h6{
  color: #000;
font-family: 'Poppins';
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: 24px; /* 150% */
letter-spacing: 0.32px;
text-transform: capitalize;
}
.service-gif-content p{
  color: #2d2d2d;
text-align: justify;
font-family: 'Poppins';
font-size: 13.5px;
font-style: normal;
font-weight: 400;
line-height: 28px;
margin-bottom: 0;
}

.gallery-btn{
  display: flex;
  justify-content: center;
  margin-top: 30px;
}
.gallery-btn a{
  padding: 10px 50px;
  border-radius: 30px;
}
.gallery-btn a:hover{
  box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
}

.team-item img{
  height: auto;
}

/*** about us CSS ***/
.about-page img {
  width: 100%;
  z-index: 1;
}

.about-pagetext h6 {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 30px;
  text-align: justify;
  color: #000000;
}

.about-pagetext h4 {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 35px;
  color: #000D2F;
}

.about-pagetext p {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 34px;
  color: #000000;
}

.about-maincontainer {
  background: rgba(0, 115, 227, 0.08);
}

.about-usrow {
  justify-content: space-between;
}

.small-extrabox {
  background: #FFFFFF;
  border-radius: 10px;
  padding: 10px;
  margin-top: -83px;
  left: 23%;
  width: 350px;
  position: absolute;
  z-index: 999;
}

.small-extrabox p {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 25px;
  color: rgba(0, 0, 0, 0.6);
}

.small-extrabox h6 {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  color: #000000;
}


/* Test CSS  */
.product-card{
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  border-radius: 10px;
}
.product-card img{
  width: 100%;
  height: 220px;
  object-fit: cover;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.product-card hr{
  margin: 0;
}
.bed-name h5{
  font-family: 'Poppins';
  color: #1b1b1b;
  font-size: 16px;
  font-weight: 500;
  padding: 10px;
  margin-bottom: 20px;
}
.bed-content{
  display: flex;
}
.bed-color img{
width: 45px;
height: 45px;
}
.bed-price strike{
  font-family: 'Poppins';
  color: #404040;
  font-weight: 600;
  font-size: 12px;
}
.bed-price h4{
  font-family: 'Poppins';
  color: #1e1e1e;
  font-weight: 600;
  font-size: 20px;
  margin-bottom: 0;
}
.bed-price span{
  font-family: 'Poppins';
  color: #c00e0e;
  font-weight: 600;
  font-size: 14px;
}
.bed-content{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}
.no-underline {
  text-decoration: none;
  color: inherit; /* Ensures the link inherits the parent's color */
}